import styled from 'styled-components';

export const PaginationContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding: 3rem 3rem !important;

  a {
    font-family: var(--font-family-text);
    font-size: var(--step-0);
    text-transform: uppercase;
    color: green;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
`;

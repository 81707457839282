import { createGlobalStyle } from "styled-components";

export const GlobalStyled = createGlobalStyle`
  :root {



    // glow globals
    --bg: hsl(246 44% 7%);
    --border: hsl(280 10% 50% / 1);
    --card: hsl(237 36% 10%);
    --color: hsl(240 18% 80%);
    --border-width: 4px;
    --border-radius: 12px;
    --gradient: conic-gradient(from 180deg at 50% 70%,hsla(0,0%,98%,1) 0deg,#eec32d 72.0000010728836deg,#ec4b4b 144.0000021457672deg,#709ab9 216.00000858306885deg,#4dffbf 288.0000042915344deg,hsla(0,0%,98%,1) 1turn);

    // colors
    --color-text: #eee;
    --color-background: #111;
    --color-grey: #333;
    --color-grey-light: #eee;
    --color-black: #000;

    // fluid typography and spacing tokens
    --step--2: clamp(0.69rem, calc(0.65rem + 0.20vw), 0.96rem);
    --step--1: clamp(0.83rem, calc(0.77rem + 0.28vw), 1.20rem);
    --step-0: clamp(1.00rem, calc(0.91rem + 0.38vw), 1.50rem);
    --step-1: clamp(1.20rem, calc(1.08rem + 0.51vw), 1.88rem);
    --step-2: clamp(1.44rem, calc(1.27rem + 0.68vw), 2.34rem);
    --step-3: clamp(1.73rem, calc(1.51rem + 0.91vw), 2.93rem);
    --step-4: clamp(2.07rem, calc(1.78rem + 1.20vw), 3.66rem);
    --step-5: clamp(2.49rem, calc(2.10rem + 1.58vw), 10000rem);



    // font
    --font-family-header: 'Sofia Sans Condensed', sans-serif;
    --font-family-text: 'Bitter', serif;
    --font-family-display: 'ADLaM Display', cursive;

    --font-size-xxxl: 7.6rem;
    --font-size-xxl: 4.8rem;
    --font-size-xl: 4rem;
    --font-size-l: 2.4rem;
    --font-size-m: 1.8rem;
    --font-weight-h1: 900;
    --font-weight-h2: 100;

    // spacing
    --space-3xs: clamp(0.25rem, calc(0.23rem + 0.09vw), 0.38rem);
    --space-2xs: clamp(0.50rem, calc(0.45rem + 0.19vw), 0.75rem);
    --space-xs: clamp(0.75rem, calc(0.68rem + 0.28vw), 1.13rem);
    --space-s: clamp(1.00rem, calc(0.91rem + 0.38vw), 1.50rem);
    --space-m: clamp(1.50rem, calc(1.36rem + 0.57vw), 2.25rem);
    --space-l: clamp(2.00rem, calc(1.82rem + 0.76vw), 3.00rem);
    --space-xl: clamp(3.00rem, calc(2.72rem + 1.14vw), 4.50rem);
    --space-2xl: clamp(4.00rem, calc(3.63rem + 1.52vw), 6.00rem);
    --space-3xl: clamp(6.00rem, calc(5.45rem + 2.27vw), 9.00rem);

    /* One-up pairs */
    --space-3xs-2xs: clamp(0.25rem, calc(0.16rem + 0.38vw), 0.75rem);
    --space-2xs-xs: clamp(0.50rem, calc(0.38rem + 0.47vw), 1.13rem);
    --space-xs-s: clamp(0.75rem, calc(0.61rem + 0.57vw), 1.50rem);
    --space-s-m: clamp(1.00rem, calc(0.77rem + 0.95vw), 2.25rem);
    --space-m-l: clamp(1.50rem, calc(1.22rem + 1.14vw), 3.00rem);
    --space-l-xl: clamp(2.00rem, calc(1.54rem + 1.89vw), 4.50rem);
    --space-xl-2xl: clamp(3.00rem, calc(2.45rem + 2.27vw), 6.00rem);
    --space-2xl-3xl: clamp(4.00rem, calc(3.08rem + 3.79vw), 9.00rem);

    /* Custom pairs */
    --space-s-l: clamp(1.00rem, calc(0.63rem + 1.52vw), 3.00rem);
  }

  @property --start {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  body {
    // linear gradient blue to black
    background: linear-gradient(180deg, #1B263B 0%, #000000 100%);

    background-color:#1B263B;
    background-position: top;
    overflow-x: hidden !important;
  }
  
  body > div {
    display: grid;
    grid-template-columns: 15% 1fr 15%;
    /* grid-template-rows: 5rem auto; */
    width: 100%;
  }
  
  html {    
    background-color: #fff;
    /* font-size: 62.5%; */
  }

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }


`;

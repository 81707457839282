const theme = {

  breakpoints: {
    // common breakpoints in px
    mobile: "480px",
    tablet: "768px",
    desktop: "1024px",
    desktopWide: "1440px",
    desktopUltraWide: "1920px",
  },

  colors: {
    background: "var(--color-background)",
    backgroundCaseStudy: "var(--color--case-study-background)",
    text: "var(--color-text)",
    navText: "var(--color-grey)",
    navTextHover: "var(--color-black)",
  },
  fontFamily: {
    caseStudyHeader: "var(--font-family-header)",
    caseStudyHeaderDescription: "var(--font-family-text)",
    caseStudyText: "var(--font-family-text)",
    caseStudyTextHeader: "var(--font-family-header)",
    hello: "var(--font-family-header)",
    navMain: "var(--font-family-text)",
    navMainLogo: "var(--font-family-sig)",
  },
  fontWeight: {
    h1: "var(--font-weight-h1)",
    h2: "var(--font-weight-h2)",
  },
  fontSize: {
    caseStudyheader: "var(--font-size-xxxl)",
    caseStudyHeaderDescription: "var(--font-size-l)",
    caseStudyText: "var(--font-size-l)",
    caseStudyTextHeader: "var(--font-size-xxl)",
    navMainLogo: "var(--font-size-xl)",
  },
  text: {
    maxWidth: "var(--text-max-width)",
  }
};

export default theme;

import React from 'react';
import { NavStyled, StyledLink } from './NavigationMain.styled';
import { useLocation, useNavigate } from 'react-router-dom';

const NavigationMain: React.FC<{ recentWorkRef: React.RefObject<HTMLDivElement> }> = ({ recentWorkRef }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const scrollToRecentWork = () => {
    if (location.pathname === '/') {
      if (recentWorkRef.current) {
        recentWorkRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate('/');
    }
  };

  return (
    <NavStyled>
      <StyledLink to="/">mike waite</StyledLink>
      <StyledLink to="/" onClick={scrollToRecentWork}>work</StyledLink>
      <StyledLink to="/about">about</StyledLink>
    </NavStyled>
  );
};

export default NavigationMain;

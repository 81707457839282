import React from 'react';
import About from '../components/About/About';
import { aboutContent } from '../content/about';

const AboutPage: React.FC = () => {
  return (
    <About 
      name={aboutContent.name}
      bio={aboutContent.bio}
      image={aboutContent.image}
    />
  );
};

export default AboutPage;

import React from 'react';
import { Link } from 'react-router-dom'; // or your routing library
import { PaginationContainer } from './Pagination.styled';

type PaginationProps = {
  prevProject: string;
  nextProject: string;
};

const Pagination: React.FC<PaginationProps> = ({ prevProject, nextProject }) => {
  return (
    <PaginationContainer>
      <Link to={`/case-study/${prevProject}`}>← {prevProject}</Link>
      <Link to={`/case-study/${nextProject}`}>{nextProject} →</Link>
    </PaginationContainer>
  );
};

export default Pagination;

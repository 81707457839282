import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CaseStudyPage from './pages/CaseStudyPage';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import { GlobalStyled } from './GlobalStyled';
import NavigationMain from './components/NavigationMain/NavigationMain';
import HomePage from './components/HomePage/HomePage';
import AboutPage from './pages/AboutPage';

const App: React.FC = () => {
  const recentWorkRef = useRef<HTMLDivElement>(null);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyled />
      <Router>
        <NavigationMain recentWorkRef={recentWorkRef} />
        <Routes>
          <Route path="/" element={<HomePage key={Date.now()} recentWorkRef={recentWorkRef} />} />
          <Route path="/case-study/:id" element={<CaseStudyPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;

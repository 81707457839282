export const aboutContent = {
  name: "Mike",
  bio: [ 
    "With over two decades in UX design, I've worn many hats—spanning roles in design, engineering, and project management.",
    "My career includes enhancing Cloudflare's design system; building visual interfaces for Kubernetes environments at Aspen Mesh; managing product design at CVS; developing tools for agile teams at Rally Software; and leading CA Technologies' first open-source design system. I also had the privilege to redesign several of Goldman Sachs' trading platforms.",
    "Based in Boulder, Colorado, I love cooking vegetarian meals, hiking the Rockies, and painting 3D-printed models. An interesting tidbit? I was in the Nintendo World Championships in the early '90s.",
    "Cheers!"
  ],
  image: "/img/mike.png",
};



import styled from 'styled-components';

export const Container = styled.section`
`;

export const Title = styled.h1`
  flex-basis: 1;
  font-family: ${(props) => props.theme.fontFamily.caseStudyHeader};
  font-size: var(--step-5);
  font-weight: ${(props) => props.theme.fontWeight.h1};
  margin-bottom: var(--space-m);
  max-width: ${(props) => props.theme.text.maxWidth};
  text-transform: uppercase;
`;

export const Description = styled.p`
  font-family: ${(props) => props.theme.fontFamily.caseStudyHeaderDescription};
  font-size: var(--step-0);
  font-weight: 500;
  line-height: 2;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: var(--step-1);
  }
`;

export const TitleContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: ${(props) => props.theme.colors.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  padding: 10%;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 10rem 25%;
  }
`;

export const StyledImage = styled.img`
padding: 10% 10% 0;
display: block;
object-fit: contain;
height: auto;
width: 100%;
`;


export const StyledContainer = styled.div`
  background: linear-gradient(#333, #666);
  max-height: 150rem;
  display: block;
  overflow: hidden;
`;
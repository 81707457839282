import React from 'react';
import { Title, Description, Container, DescriptionContainer } from './CaseStudyText.styled';

type Props = {
  customPadding?: boolean;
  description: string | string[];
  paddingBottom?: string;
  paddingTop?: string;
  title: string;
}

const CaseStudyText: React.FC<Props> = ({ title, description, paddingBottom, paddingTop }) => {
  return (
    <Container 
      paddingBottom={paddingBottom} 
      paddingTop={paddingTop}
    >
      <Title>{title}</Title>
      <DescriptionContainer>
        {Array.isArray(description) 
          ? description.map((para, index) => <Description key={index}>{para}</Description>)
          : <Description>{description}</Description>
        }
      </DescriptionContainer>
    </Container>
  );
}

export default CaseStudyText;


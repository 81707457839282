import cloudflare from './cloudflare';
import aspenmesh from './aspenmesh';
import mineral from './mineral';
import { aboutContent } from './about';

const caseStudies = {
  cloudflare,
  aspenmesh,
  mineral,
};

export { aboutContent };
export default caseStudies;

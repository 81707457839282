const cloudflare = {
  id: "cloudflare",
  title: "Cloudflare",
  cardText: "Led a design system team to create a unified design language that combined Figma and component library assets. Resulted in accessiblity certification and reduction in components and design assets.",
  link: "./case-study/cloudflare",
  description: "As a key player in the digital landscape, Cloudflare serves millions of online properties, offering an impressive array of services that range from content delivery network solutions and cutting-edge cybersecurity measures to DDoS mitigation and ICANN-accredited domain registration services.",
  imageUrl: "/img/cf-link-img.png",
  backgroundImageUrl: "/img/cf-clouds.png",
  backgroundPosition: "center",
  cardImageUrl: "/img/cf-link-img.png",
  gradientColor: "orange",
  altText: "A screenshot of Cloudflare's Workers page.",
  images: [
    {
      url: "/img/cf-sitemap.png",
      alt: "As Cloudflare's products grew away from each other, I strategized standard page templates to unify the UX. We began by sorting pages into tools, overviews, and details."
    },
    {
      url: "/img/cf-map.png",
      alt: "The contribution process was a key part of understanding our user's needs. I mapped out the process to identify pain points and opportunities."
    },
    {
      url: "/img/cf-exploration.png",
      alt: "Presenting design to stakeholders was a key part of the process. I explored various prototypes using Figma and HTML to present the design system changes to the company."
    },
    {
      url: "/img/cf-audit.png",
      alt: "Audting component usage gave us a roadmap to reduce code and design debt. It is never enough to produce only design tokens, following the developer experience is key to a successful design system."
    },
    {
      url: "/img/cf-screens.png",
      alt: "The success of a design system is to see the patterns flow into all products. Assessing breakpoints, accessiblity and semantic HTML results in a better user experience."
    },
  ],
  role: {
    customPadding: true,
    description: "Spearheading the design system team, my focus was on the graphical user interface (GUI) side of Cloudflare's diverse product offerings. Our dynamic group, made up of dedicated product designers and front-end engineers, worked collaboratively to create an exceptional user experience.",
    paddingTop: "10rem",
    title: "My Role",
  },
  strategyVision: {
    description: "The design system team was responsible for the creation and maintenance of the Cloudflare Design System, which was a collection of reusable components and design guidelines used by product designers and engineers to create a consistent user experience across all Cloudflare products. By establishing robust roadmaps, generating prototypes, and setting design principles, I was able to guide agile planning processes and foster a shared vision. This 'north-star' provided a vital touchstone, keeping all teams synchronized and motivated towards a common goal.",
    paddingBottom: "10rem",
    title: "Strategy and Vision",
  },
  planning: {
    description: "I worked closely with product designers and front-end engineers to create a roadmap for the design system. This roadmap was used to plan out the design system's future and to prioritize features and improvements. I also worked with product designers to create prototypes and mockups for new components and features. These prototypes were used to test out new ideas and to gather feedback from stakeholders.",
    paddingBottom: "10rem",
    title: "Planning",
  },
  designEngineeringExecution: {
    description: [ 
      "I worked closely with front-end engineers to create new components and to improve existing components. I also worked with product designers to create new design guidelines and to improve existing guidelines. These guidelines were used to ensure consistency across all Cloudflare products.",
      "I employed pair programming methods, working in close collaboration with the team to ensure harmonious execution of tasks. This encompassed work on platforms like Figma and Miro, as well as scripting languages like React and CSS."
    ],
    title: "Design & Engineering Execution",
  },
  leadership: {
    description: [
      "As the design system team lead, I was responsible for managing the team's day-to-day operations. This included assigning tasks, reviewing pull requests, and providing feedback.",
      "Presenting our work to the company's stakeholders and various teams was instrumental in gaining buy-in and establishing a shared vision. This was a critical part of our ongoing mission to ensure alignment across the diverse product landscape."
    ],
    paddingBottom: "10rem",
    title: "Leadership",
  },
  challenge: {
    description: [ 
      "Cloudflare's product ecosystem, developed over 7 years in a fast-paced start-up environment, was constructed without an overarching strategy for product cohesion. Each product had its unique UX, engineering implementation, and sometimes, distinct development schema.",
      "Our users pointed out inconsistencies in product experiences, often stating that the UX of one product felt completely different from the others. This led to recurring issues where localized fixes inadvertently introduced new problems, which in turn fostered a sense of apathy among the engineering team.",
      "The rapid build-and-release cycle did not prioritize post-launch maintenance, causing a mindset that favored launching new features while downplaying issues. Customer feedback was often dismissed as bugs, reducing meaningful user input to mere product roadmap deviations."
    ],
    title: "The Challenge: Creating a unified design launguage",
  },
  strategy: {
    description: "A strategic pivot was needed to showcase to the business that a less frantic development pace could yield faster feature release cycles in the long run. It was important to address the disproportionate attention to customer feedback versus new feature work.",
    paddingBottom: "10rem",
    title: "The Strategy: Minimize design and engineering debt",
  },
  streamlingComponentLibraries: {
    description: [
      "Internally, the redundant creation of the same code was hampering the user experience. After consulting the engineers, it was evident that multiple instances of similar yet incomplete or bug-ridden components existed.",
      "By auditing all components used across the products and creating a backlog to tackle the issue, we began to streamline our library. This led to a unified component schema, foundational design system concepts, reusable tokens, and an established design language."
    ],
    title: "Streamlining Component Libraries",
  },
  reducingPatterns: {
    title: "Reducing UX Pattern Redundancy",
    description: [
      "The redundancy in the component library reflected deeper cultural challenges. This was evident in the inconsistent resolution of UX patterns by the product designers. Conflicting design decisions often led to the creation of new components that failed to be implemented outside of their immediate focus, leading to an overall degraded user experience.",
      "A clear inventory of design assets helped us identify common UX approaches and establish a separate Figma library that aligned with our streamlined component library. We created prototypes to visualize necessary product modifications and garnered feedback from stakeholders.",
    ],
  },
  accessibility: {
    title: "Prioritizing Accessbility and Semantic HTML",
    description: [
      "Our fast-shipping culture was initially resistant to changes, viewing them as potential delays. However, an accessibility audit that revealed significant issues forced us to prioritize changes to our design and coding practices. The focus on accessibility and semantic HTML not only improved user experience but also broke the cycle of redundant asset patterns.",
    ],
  },
  results: {
    title: "Results",
    description: [
      "Changing a company's culture is a long-term project. By focusing on creating a lean, accessible, and semantically correct footprint, we reduced low-quality work in web development. We successfully streamlined Figma assets and component libraries, enabling our team to create better user experiences.",
      "A federated contribution model was established, helping to reduce redundancy and foster collaboration between the design system team and the product teams. The usage of design system tokens was aligned with modern CSS practices and Figma tokens, enhancing accessibility and ensuring a consistent design language across all products.",
    ],
    paddingBottom: "10rem"
  },
};

export default cloudflare;

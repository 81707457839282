import React from 'react';
import { HelloStyled, HelloContainer, HelloText } from './Hello.styled';

const Hello: React.FC = () => {
  return (
    <HelloContainer>
      <HelloStyled>
        Creative product designer, mentor, and consultant for design systems.
      </HelloStyled>
      <HelloText>
        Please enjoy my work examples below.
      </HelloText>
    </HelloContainer>
  );
};

export default Hello;

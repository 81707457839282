import styled from 'styled-components';

type StyledProps = {
  paddingBottom?: string;
  paddingTop?: string;
};

export const Container = styled.section<StyledProps>`
  background-color: white;
  display: inline-block;
  padding: var(--space-xl) 10%;
  width: 100%;

  ${props => props.paddingTop && `
    padding-top: ${props.paddingTop};
  `}

  ${props => props.paddingBottom && `
    padding-bottom: ${props.paddingBottom};
  `}

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: var(--space-xl) 15%;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: var(--space-xl) 25%;
  }
`;

export const Title = styled.h2`
  flex-basis: 1;
  font-family: ${(props) => props.theme.fontFamily.caseStudyTextHeader};
  font-size: var(--step-3);
  font-weight: ${(props) => props.theme.fontWeight.h2};
  line-height: 1.25;
  max-width: ${(props) => props.theme.text.maxWidth};
  padding-bottom: var(--space-s);
  text-transform: uppercase;
`;

export const Description = styled.p`
  font-family: ${(props) => props.theme.fontFamily.caseStudyText};
  font-size: var(--step-0);
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: var(--space-xs);

  &:last-child {
    margin-bottom: 0;
  }
  `;

  export const DescriptionContainer = styled.div`
  `;

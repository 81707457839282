import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CaseStudyImage from '../components/CaseStudyImage/CaseStudyImage';
import caseStudiesImport from '../content';
import CaseStudyTitle from '../components/CaseStudyTitle/CaseStudyTitle';
import CaseStudyText from '../components/CaseStudyText/CaseStudyText';
import CaseStudyContainer from '../components/CaseStudy/CaseStudyContainer';
import Pagination from '../components/Pagination/Pagination';

type CaseStudySection = {
  title: string;
  description: string | string[];
  paddingBottom?: string;
  paddingTop?: string;
};

type ImageData = {
  alt: string;
  url: string;
};

type CaseStudySections =
  | 'accessibility'
  | 'challenge'
  | 'designEngineeringExecution'
  | 'leadership'
  | 'planning'
  | 'reducingPatterns'
  | 'results'
  | 'role'
  | 'strategy'
  | 'strategyVision'
  | 'streamlingComponentLibraries';

type CaseStudyData = {
  [K in CaseStudySections]?: CaseStudySection;
} & {
  altText?: string;
  description: string;
  id: string;
  imageUrl?: string;
  images?: ImageData[];
  title: string;
};

type CaseStudies = {
  [key: string]: CaseStudyData;
};

type SectionOrImage = keyof CaseStudyData | { imageIndex: number };

const caseStudies: CaseStudies = caseStudiesImport as CaseStudies;

const CaseStudyPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  const caseStudyData = id ? caseStudies[id] : undefined;
  
  const caseStudyIds = Object.keys(caseStudies);
  const currentIndex = id ? caseStudyIds.indexOf(id) : -1;
  const totalProjects = caseStudyIds.length; // Total number of projects in your list
  
  const prevIndex = (currentIndex - 1 + totalProjects) % totalProjects; // Calculate previous index
  const nextIndex = (currentIndex + 1) % totalProjects; // Calculate next index
  
  const prevProject = caseStudyIds[prevIndex];
  const nextProject = caseStudyIds[nextIndex];

  const sections: SectionOrImage[] = [
    'role',
    'strategyVision',
    { imageIndex: 0 },
    'planning',
    { imageIndex: 1 },
    'designEngineeringExecution',
    'leadership',
    { imageIndex: 2 },
    'challenge',
    'strategy',
    { imageIndex: 3 },
    'streamlingComponentLibraries',
    'reducingPatterns',
    'accessibility',
    'results',
    { imageIndex: 4 },
  ];

  if (!caseStudyData) {
    return <div>Case study not found</div>;
  }

  return (
    <CaseStudyContainer>
      <CaseStudyTitle 
        title={caseStudyData.title} 
        description={caseStudyData.description} 
        imageUrl={caseStudyData.imageUrl}
        altText={caseStudyData.altText}
      />
      {sections.map((sectionOrImage: SectionOrImage, index) => {
        if (typeof sectionOrImage === 'string') {
          const sectionData = caseStudyData[sectionOrImage];
          if (sectionData && typeof sectionData === 'object' && 'title' in sectionData) { 

            return (
              <CaseStudyText
                key={sectionOrImage}
                title={sectionData.title}
                description={sectionData.description}
                paddingTop={sectionData.paddingTop}
                paddingBottom={sectionData.paddingBottom}
              />
            );
          }
        } else {
          const image = caseStudyData.images?.[sectionOrImage.imageIndex];
          if (image) {
            return <CaseStudyImage key={`img-${index}`} src={image.url} alt={image.alt} />;
          }
        }
        return null;
      })}
      {(prevProject || nextProject) && (
      <Pagination prevProject={prevProject} nextProject={nextProject} />
      )}
    </CaseStudyContainer>
  );  
};

export default CaseStudyPage;

import styled from 'styled-components';

type GradientColors = 'orange' | 'blue'; // Add other color names here

const getGradient = (color: GradientColors) => {
  switch (color) {
    case 'orange':
      return 'linear-gradient(to bottom, rgba(251, 224, 197, 0) 25vh, rgba(251, 224, 197, 1) 100vh)';
    case 'blue':
      return 'linear-gradient(to bottom, rgba(173, 216, 230, 0) 25vh, rgba(176, 196, 222, 1) 100vh)';
    default:
      return 'linear-gradient(to bottom, rgba(251, 224, 197, 0) 25vh, rgba(251, 224, 197, 1) 100vh)';
  }
};


export const StyledCaseStudyContainer = styled.div<{ backgroundImage?: string, gradientColor?: GradientColors}>`
  /* background-image: url(${props => props.backgroundImage || '/img/cf-clouds.png'}); */
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  height: 100%;
  grid-column-end: 4;
  justify-content: center; // Center-align ContentContainer
  position: relative;
  width: 100%;

  &::before {
    content: "";
    background-image: ${props => getGradient(props.gradientColor || 'orange')};
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
`;

export const ContentContainer = styled.main`
  box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1500px;
  z-index: 1;

  & > :last-child {
    padding-bottom: 20rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 10rem;
    width: 90%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 70%;
  }
`;

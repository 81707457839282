import React from 'react';
import { AboutContainer, DataContainer, Name, ParagraphContainer, Portrait, Text } from './About.styles';

type AboutProps = {
  name: string;
  bio: string | string[];
  image: string;
};

const About: React.FC<AboutProps> = ({ name, bio, image }) => {
  return (
    <AboutContainer>
      <DataContainer>
        <Name>About Mike</Name>
        <Portrait src={image} alt={`${name}'s portrait`} />
        <ParagraphContainer>
          {Array.isArray(bio) 
            ? bio.map((para, index) => <Text key={index}>{para}</Text>)
            : <Text>{bio}</Text>
          }
        </ParagraphContainer>
      </DataContainer>  
    </AboutContainer>
  );
};

export default About;

const mineral = {
  altText: "lorm ipsum",
  description: "Founder of Mineral UI, a React component library for building design systems. I am responsible for the overall project management and backend.",
  id: "mineral",
  cardText: "Founder of Mineral UI, an open source React component library for building design systems. Led the project management and managed the engineering team. Facilitated the design process and product roadmap.",
  imageUrl: "/img/mui-home.png",
  cardImageUrl: "/img/mui-colors.png",
  backgroundImageUrl: "/img/puzzle.png",
  backgroundPosition: "center",
  title: "Mineral UI",
  link: "./case-study/mineral",
  images: [
    {
      url: "/img/mui-install.png",
      alt: "Design sprints were the best way to get the business aligned to the various different perspectives of all the team members. Aligning on a clear user journey allowed us to focus on the most important features."
    },
    {
      url: "/img/mui-roadmap.png",
      alt: "All design assets were documented for facilitating feedback. This allowed us to work asynchronously and iterate quickly, reducing the need for constant in-person meetings."
    },
    {
      url: "/img/mui-spacing.png",
      alt: "Living documentation is very important to keep stakeholders informed. They often do not have the same focus as other members of the team and their ability to quickly understand the changes is valuable."
    },
    {
      url: "/img/mui-colors.png",
      alt: "Creating visualizaitons allowed users other than the SRE's to understand the complex Kubernetes environment. This was important to exlpain an outage or to speak to customers outside of a techincal expertise."
    },
  ],
  role: {
    customPadding: true,
    description: "As the founder, designer, engineering manager, and product manager of Mineral-UI, I orchestrated every aspect of the project, from initial conception to execution. Being at the helm of leadership allowed me to create a comprehensive vision for Mineral-UI, aligning strategies and objectives to create a cohesive and efficient development process. ",
    paddingTop: "10rem",
    title: "My Role",
  },
  strategyVision: {
    description: "The core vision for Mineral-UI was to create an open-source component library that would be accessible, reusable, efficient, and versatile. I translated this vision into actionable goals and principles, guiding every aspect of development, design, and community engagement. My approach emphasized collaboration, responsiveness to community needs, and alignment with industry best practices. Through consistent communication and an agile development philosophy, the team and the broader community were always aligned towards our shared 'north-star'.",
    paddingBottom: "10rem",
    title: "Strategy and Vision",
  },
  planning: {
    description: "Planning for Mineral-UI follows a “scrumban” agile methodology. The roadman was defined by continuous dialogue with contributors and users, I ensured that our plans were adaptable and responsive. The engineering and design teams worked without direct timelines, rather a commitment to a transparent status update. This was communicated weekly with internal stakeholders and displayed publicly on the website.",
    paddingBottom: "10rem",
    title: "Planning",
  },
  designEngineeringExecution: {
    description: [
      "My role in design and engineering execution encompassed everything from guiding the creation of new components to refining existing ones. Using React, I ensured that the library's structure was consistent, scalable, and maintainable. Working closely with contributors, our environment was a place that  innovative ideas could thrive, balanced with rigorous code reviews and accessibility standards.",
      "Pair programming, iterative design, feedback loops, and hands-on engagement with coding ensured that the project moved forward under a shared vision. My pragmatic approach helped bridge the gap between design and engineering, leading to a more predictable and efficient execution."
    ],
    title: "Design & Engineering Execution",
  },
  challenge: {
    description: [
      "The creation of Mineral-UI was filled with complex challenges. Developing an open-source component library demanded maintainability, innovation, and alignment with community expectations. Striking the right balance between design aspirations and practical development needs required continuous refinement.",
      "Ensuring that the library remained lean, while also providing robust and reusable components, necessitated a concerted effort in planning, review, and ongoing support.",
      "Overcoming expectations of older frameworks was the main task of the component library. Applying a design system for our clients based on the structure of Mineral-UI was more of a task of changing culture rather than keeping coding trends up to date."
    ],
    title: "The Challenge: Creating a unified design language",
  },
  strategy: {
    description: "My strategy for Mineral-UI emphasized embracing modern development, design and product management trends. I recognized the need to create not just a product but an ecosystem where contributors and users felt heard and valued. Transparent development practices and a willingness to iterate and adapt ensured that Mineral-UI remained relevant and aligned with user needs. A focus on documentation not only in the website or code environment, but as written articles to the community solidified the library's position as a popular open resource.",
    paddingBottom: "10rem",
    title: "The Strategy: Building a community-driven library",
  },
  accessibility: {
    title: "Prioritizing Accessibility and Semantic HTML",
    description: [
      "Accessibility and semantic correctness were more than just technical requirements for Mineral-UI; they were core values. Our team drove to ensure compliance with accessibility standards and executed best practices in coding. Accessibility was never an afterthought but a foundational principle.",
      "Every component was tested with screen reader technologies across multiple browsers. The emphasis on semantic HTML further enriched the user experience, aligning the library with modern web standards, and enhancing its usability across platforms and devices."
    ],
  },
  results: {
    title: "Results",
    description: [
      "Mineral-UI's success is reflected in its adoption and impact within the open-source community. Through careful leadership, vision, and execution, the library has become a trusted resource, offering high-quality, accessible, and reusable components.",
      "The unified design language, streamlined processes, and commitment to community values have cemented Mineral-UI's reputation as a leading open-source component library."
    ],
    paddingBottom: "10rem",
  },
};

export default mineral;

import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const NavStyled = styled.nav`
  align-items: center;
  background-color: #000; 
  border-bottom: 1px solid #333;
  display: flex;
  gap: 3rem;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 4;
  padding: 2rem 10%;
  width: 100%;
  white-space: nowrap;
  

  & > *:first-child {
    font-size: var(--step-1);
    margin-right: auto;
  }
`;

export const StyledLink = styled(RouterLink)`
  color: #ddd;
  font-family: ${({ theme }) => theme.fontFamily.navMain};
  font-size: var(--step--1);
  letter-spacing: 0.05rem;
  outline: none;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #dccaa5;
    transition: color 0.3s ease-in-out;
  }
`;
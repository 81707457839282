import styled from 'styled-components';

export const HelloContainer = styled.div`
  display: flex;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1; 
  grid-row-end: 2;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 10% 8rem;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 1);

  @media screen and  (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0;
    grid-column-start: 2;
    grid-column-end: 3;
    padding: 8rem 0 12rem;
  }

  @media screen and  (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 15rem 0 15rem;
  }
`;

export const HelloStyled = styled.h1`
  color: #fff;  
  font-family: ${({ theme }) => theme.fontFamily.text};
  text-shadow: 0 0 1.5rem 1 rgba(0, 0, 0, 1);
  font-size: var(--step-4);
  font-weight: 200;
  line-height: 1.2;
  max-width: 50rem;
  margin-bottom: var(--space-s);
`

export const HelloText = styled.p`
  color: #b0c4de;
  font-family: ${({ theme }) => theme.fontFamily.hello};
  text-shadow: 0 0 1.5rem 1 rgba(0, 0, 0, 1);
  font-size: var(--step-1);
  font-weight: 300;
  line-height: 1.25;
  max-width: 30rem;

  @media screen and  (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 30vw;
  }
`;
import React from 'react';
import Hello from '../Hello/Hello';
import RecentWork from '../RecentWork/RecentWork';
import { HomePageStyled } from './HomePage.styled';

const HomePage: React.FC<{ recentWorkRef: React.RefObject<HTMLDivElement> }> = ({ recentWorkRef }) => {
  return (
    <HomePageStyled>
      <Hello />
      <RecentWork recentWorkRefProp={recentWorkRef} />
    </HomePageStyled>
  );
};

export default HomePage;

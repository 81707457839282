import styled from 'styled-components';

export const AboutContainer = styled.main`
  background: url(/img/aspen.png) no-repeat;  
  background-color: white;
  background-size: 100% auto;
  display: grid;
  color: white;
  flex-direction: column;
  grid-template-columns: 10vw auto 10vw;
  grid-template-rows: auto;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4;  
  height: 100%;
  padding: 50px 5%;
  width: 100%;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}) { 
   background-size: cover; 
   background-position: bottom;
   min-height: 100vh;
  }
`;

export const DataContainer = styled.div`
  background-color: rgba(0,0,0,0.9);  
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  padding: 5%;
  margin-bottom: 10%;
  & > * {
    margin-bottom: 30px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: 0;
    width: 100%;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}) {  
    display: grid;
    padding-left: 10%;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto 1fr;
    width: fit-content;
    margin: 0 auto;
  }
`;

export const Name = styled.h1`
  font-family: var(--font-family-header);
  font-size: var(--step-4);
  line-height: 1.5;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
`;

export const ParagraphContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: column;
`;

export const Portrait = styled.img`
justify-self: center;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    min-height: auto;
    max-height: 40vh;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 4;
  }
`;

export const Text = styled.p`
  font-family: var(--font-family-text);
  font-size: var(--step-1);
  line-height: 2;
  margin-bottom: 2rem;
  flex: 1;

  &:first-of-type {
    font-size: var(--step-3);
    line-height: 1.5;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 10%;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: inline; // For inline display
    max-width: 40vw;
  }
`;

const aspenmesh = {
  id: "aspenmesh",
  title: "Aspen Mesh",
  cardText: "As the sole designer in a start-up, I paired with engineers and product managers to facilitate the design process. Designed and developed a number of kubernetes products.",
  link: "./case-study/aspenmesh",
  description: "Aspen Mesh is a burgeoning Kubernetes startup, committed to visualizing container orchestration and management. In a complex CLI only environment, the company focuses on delivering robust and user-friendly solutions to a broad range of site reliability engineers.",
  imageUrl: "/img/am-chart.png",
  backgroundImageUrl: "/img/gears.png",
  cardImagePosition: "top left",
  cardImageUrl: "/img/am-nodes-proto.png",  
  gradientColor: "blue",
  altText: "A screenshot of Aspen Mesh's product",
  images: [
    {
      url: "/img/am-sprint.png",
      alt: "Design sprints were the best way to get the business aligned to the various different perspectives of all the team members. Aligning on a clear user journey allowed us to focus on the most important features."
    },
    {
      url: "/img/am-details-wireframe.png",
      alt: "All design assets were documented for facilitating feedback. This allowed us to work asynchronously and iterate quickly, reducing the need for constant in-person meetings."
    },
    {
      url: "/img/am-sitemap.png",
      alt: "Living documentation is very important to keep stakeholders informed. They often do not have the same focus as other members of the team and their ability to quickly understand the changes is valuable."
    },
    {
      url: "/img/am-nodes-proto.png",
      alt: "Creating visualizaitons allowed users other than the SRE's to understand the complex Kubernetes environment. This was important to exlpain an outage or to speak to customers outside of a techincal expertise."
    },
    {
      url: "/img/am-table.png",
      alt: "Rolling up your system's metrics into a table allows for quick analysis of the health of your system. This is a great way to track down issues and to understand the pathing of your data."
    },
  ],
  role: {
    customPadding: true,
    description: [
      "At Aspen Mesh, I was the sole designer, playing a multifaceted role in shaping the product from concept to execution. My collaboration with a talented front-end engineer was central to my work, and our teamwork helped to create a cohesive and efficient process.",
      "I was responsible for the overall design and development of the product, from the initial research and ideation stages to the final implementation. I worked closely with the engineers, data scientists and product technical sales team to ensure that the design was effectively translated into the final product."
    ],
    paddingTop: "10rem",
    title: "My Role",
  },
  strategyVision: {
    description: "I initiated and conducted user research to understand the needs and preferences of our target audience. Design sprints were conducted to brainstorm and plan the product strategy. By creating wireframes, information architecture, and visual designs, I laid the groundwork for the development of our solutions, always keeping the end-user in mind.",
    paddingBottom: "10rem",
    title: "Strategy & Vision",
  },
  planning: {
    description: [
      "As the project evolved, I was hands-on with prototyping and contributed to the code, ensuring that the vision was effectively translated into the final product. The use of tools such as Adobe XD, Miro, and an open source component library allowed for a seamless transition between design and development stages.",
      "Design assets included journey maps, wireframes, site mapping, visual designs, and live prototypes. I also created a design system to ensure consistency across the product and to facilitate future development.",
      "Start-ups often don't need the overhead of creating yet another design system. We used an Uber's open source component library to lay down the framework for our UI. Making global changes to the UI was as simple as changing a few variables."
    ],
    title: "Design & Engineering Execution",
  },
  designEngineeringExecution: {
    description: [
      "I actively presented work, solicited feedback, and drove alignment within the organization. My leadership extended to mentoring and guidance, not only in design but in fostering a culture of continuous improvement and innovation.",
      "The fast-paced startup environment required quick iterations without compromising on quality or losing sight of the broader ecosystem. In order to be as effecient as possible, all design assets were presented in a living document that highlighted the changes, ommission or additiongs to the site topography. This allowed the leadership team to advocate for the designs outside of my presence."
    ],
    title: "Leadership in a Lean Environment",
  },
  challenge: {
    description: [
      "Aspen Mesh's mission was to provide a Kubernetes installation solution, but the challenge lay in creating a user experience that is easy for first time users, but also useful for users that strictly use the CLI.  The environment of  Kubernetes and particularly Istio is fast-paced and requires quick feature iterations without compromising on quality or losing sight of the broader ecosystem."
    ],
    title: "Challenge: Kubernetes Web Visualization for the CLI"
  },  
  strategy: {
    description: [
      "The finished product took the form of a monitoring visualization that demonstrates the relationship of traffic between various nodes of the Kubernetes flow. Normally, this is something you would interface with in the CLI after receiving information from sources like AWS.",
      "Machine learning was used to generate scenarios of where traffic was increasing and to prevent an outage, new nodes can be built or torn down to accommodate the changes. The UI was presented in both a visualization and in tabular form for accessibility considerations. Users can click into nodes to see the full traffic flow."
    ],
    paddingBottom: "10rem",
    title: "Solutions"
  },
  results: {
    description: [
      "Users are better able to understand the delta between what they wanted to accomplish in their Istio configs and the errors created. Using Aspen Mesh’s visualization tool, SRE’s are able to create scenarios to address traffic flows, analyze post-mortem failed configurations, and otherwise monitor the health of their network."
    ],
    paddingBottom: "10rem",
    title: "Results"
  },    
};

export default aspenmesh;

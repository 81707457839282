import React from 'react';
import { Title, TitleContainer, Description, Container, StyledImage, StyledContainer } from './CaseStudyTitle.styled';

type CaseStudyTitleProps = {
  title: string;
  description: string;
  imageUrl?: string;
  altText?: string;
}

const CaseStudyTitle: React.FC<CaseStudyTitleProps> = ({ title, description, imageUrl, altText }) => {
  
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TitleContainer>
      <StyledContainer>
        <StyledImage src={imageUrl} alt={altText} />
      </StyledContainer>
    </Container>
  );
}

export default CaseStudyTitle; 
import styled from 'styled-components';

export const RecentWorkLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
	padding: 0 10vw;
  scroll-margin-top: 20rem;
  scroll-behavior: smooth;
  width: 100vw;

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
		display: grid;
		grid-column-start: 2;
		grid-column-end: 3;
		grid-gap: 4rem; 
		grid-row-start: 2;
		grid-row-end: 3;
		grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
		height: auto;
		justify-content: center;
		padding: 0;
		scroll-margin-top: 20rem;
		scroll-behavior: smooth;
		width: 100%;
  }

  /* @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    grid-template-columns: repeat(4, 1fr); // 4 columns for desktop size
    grid-gap: 1rem; // Further adjustment for larger screens
  }  */
`;

export const RecentWorkBackground = styled.section`
	background: linear-gradient(180deg, #000, #555);
	border-top: 1px solid #888;
	display: grid;
	grid-template-columns: 15% 1fr 15%;	
	grid-column-start: 1;
	grid-column-end: 4;
	grid-row-start: 2;
	grid-row-end: 3;
`;

export const RecentWorkMask = styled.div`
	filter: blur(calc(var(--blur) * 1px));
	inset: 0;
	pointer-events: none;
	position: absolute;

	&::after,
	&::before {
		--alpha: 0;
		content: "";
		background: var(--gradient);
		background-attachment: fixed;
		border: 10px solid transparent;
		border-radius: var(--border-radius);
		inset: -5px;
		mask:
			linear-gradient(#0000, #0000),
			conic-gradient(from calc((var(--start) - (var(--spread) * 0.5)) * 1deg), #000 0deg, #fff, #0000 calc(var(--spread) * 1deg));
		mask-composite: intersect;
		mask-clip: padding-box, border-box;
		opacity: var(--active);
		position: absolute;
		transition: opacity 1s;
	}
`;

export const RecentWorkContainer = styled.article<{ backgroundImage: string, backgroundPosition: string }>`  
	--active: 0.15;
	--start: 0;
	background: no-repeat left url(${props => props.backgroundImage}), linear-gradient(to bottom, #000, #222);
	background-size: cover;
	background-position: ${props => props.backgroundPosition};
	border-radius: var(--border-radius);
	border: 0.5px solid black;
	box-shadow: inset 0 0 0 10px black;
	display: flex;
	/* filter: grayscale(80%); */
	flex-direction: column;
	height: 300px;
	margin: -2rem 0 4rem;
	padding: 2rem;
	width: 100%;
  transition: filter 0.5s ease-in-out, transform 0.5s ease-in-out;

	&:is(:hover, :focus-visible) {
		z-index: 2;
	}

	&:hover,
	&:focus {
		filter: grayscale(0%);
		transform: scale(1.025);
		transition: filter 0.5s ease-in-out, transform 0.5s ease-in-out;
	}

	&::before {
		position: absolute;
		inset: 0;
		border: var(--border-width) solid transparent;
		content: "";
		border-radius: var(--border-radius);
		pointer-events: none;
		background: var(--border);
		background-attachment: fixed;
		border-radius: var(--border-radius);
		mask:
			linear-gradient(#0000, #0000),
			conic-gradient(
				from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) * 1deg),
				hsl(0 0% 100% / 0.15) 0deg,
				white,
				hsl(0 0% 100% / 0.15) calc(var(--spread) * 2.5deg));
		mask-clip: padding-box, border-box;
		mask-composite: intersect;
		opacity: var(--active);
		transition: opacity 1s;
	}

	&::after {
		--bg-size: 100%;
		content: "";
		pointer-events: none;
		position: absolute;
		background: var(--gradient);
		background-attachment: fixed;
		border-radius: var(--border-radius);
		opacity: var(--active, 0);
		transition: opacity 1s;
		--alpha: 0;
		inset: 0;
		border: var(--border-width) solid transparent;
		mask:
			linear-gradient(#0000, #0000),
			conic-gradient(from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) * 1deg), #0000 0deg, #fff, #0000 calc(var(--spread) * 0.5deg));
		filter: brightness(1.5);
		mask-clip: padding-box, border-box;
		mask-composite: intersect;
	}

	h2 {
		color: Red;
		font-family: var(--font-family-text);
		font-size: var(--step-2);
		font-weight: 600;
		margin-bottom: var(--space-s);
		width: fit-content;
	}

	p {
		font-family: var(--font-family-text);
		font-size: var(--step-0);
		line-height: 2;
		max-width: 90%; 			
		}

	@media screen and (min-width: ${(props) => props.theme.breakpoints.tablet}) {
		height: 500px;
		margin: -3rem 0 3rem;
		max-width: 800px;
		width: initial;
	}

	@media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}) {


		a {
			margin-top: auto;
		}

		p {
			font-size: var(--step--1);
		}
	}
`;




import React, { useState, useEffect } from 'react'; // Import useEffect
import { 
  StyledImage, 
  StyledContainer, 
  ImageCaption, 
  ModalContainer,
  ModalImage,
  ModalImageWrapper,
} from './CaseStudyImage.styled';

type CaseStudyImageProps = {
  src: string;
  alt: string;
};

const CaseStudyImage: React.FC<CaseStudyImageProps> = ({ src, alt }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  // Effect to handle scrolling behavior
  useEffect(() => {
    if (isModalOpen) {
      // Disable scrolling on the body
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling on the body
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to reset scrolling behavior
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]); // Dependency on isModalOpen

  return (
    <>
      {isModalOpen && (
        <ModalContainer onClick={() => setModalOpen(false)}>
          <ModalImageWrapper>
            <ModalImage src={src} alt={alt} />
          </ModalImageWrapper>
        </ModalContainer>
      
      )}
      <StyledContainer onClick={() => setModalOpen(true)}>
        <StyledImage src={src} alt={alt} />
        <ImageCaption>{alt}</ImageCaption>
      </StyledContainer>
    </>
  );
};

export default CaseStudyImage;

import styled from 'styled-components';

export const HomePageStyled = styled.main`
  background-image: url(/img/triangles.svg);
  background-position: top left;
  background-size: 200%;
  background-repeat: no-repeat;
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-template-columns: 15% 1fr 15%;
  grid-template-rows: auto auto;
  inset: 0px;
  mix-blend-mode: hard-light;
  width: 100%;
  
  @media (min-width: 1100px) {
    background-size: 100%;
    background-position: top center;
  }
/* 
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    background-size: contain;
  } 

  @media (min-width: ${(props) => props.theme.breakpoints.desktopWide}) { 
    background-size: cover;
  }   */
`;


import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { RecentWorkBackground, RecentWorkContainer, RecentWorkLayout,RecentWorkMask } from './RecentWork.styled';
import caseStudies from '../../content';

type CaseStudy = {
	id: string;
	title: string;
	cardText: string;
	link: string;
	cardImageUrl: string;
	cardImagePosition?: string;
};

const caseStudyArray = Object.values(caseStudies);
const RecentWork: React.FC<{ recentWorkRefProp: React.RefObject<HTMLDivElement> }> = ({ recentWorkRefProp }) => {
const containerRef = useRef<HTMLElement | null>(null);

	useEffect(() => {
		if (!containerRef.current) return; 

			const CONTAINER = containerRef.current;
			const CARDS = Array.from(CONTAINER.querySelectorAll('article'));
			const CONFIG = {
				proximity: 40,
				spread: 80,
				blur: 20,
				gap: 32,
				vertical: false,
				opacity: 0,
			}

			const RESTYLE = () => {
				CONTAINER.style.setProperty('--gap', CONFIG.gap.toString())
				CONTAINER.style.setProperty('--blur', CONFIG.blur.toString())
				CONTAINER.style.setProperty('--spread', CONFIG.spread.toString())
				CONTAINER.style.setProperty('--direction', CONFIG.vertical ? 'column' : 'row')
			}

			RESTYLE(); // Call the function to apply the styles

			const UPDATE = (event: PointerEvent) => {
				for (const CARD of CARDS) {
					const CARD_BOUNDS = CARD.getBoundingClientRect()
						const CARD_CENTER = [
									CARD_BOUNDS.left + CARD_BOUNDS.width * 0.5,
									CARD_BOUNDS.top + CARD_BOUNDS.height * 0.5
						]
						if (
							event.x > CARD_BOUNDS.left - CONFIG.proximity &&
							event.x < CARD_BOUNDS.left + CARD_BOUNDS.width + CONFIG.proximity &&
							event.y > CARD_BOUNDS.top - CONFIG.proximity &&
							event.y < CARD_BOUNDS.top + CARD_BOUNDS.height + CONFIG.proximity) {
								CARD.style.setProperty('--active', '1')
							} else {
								CARD.style.setProperty('--active', CONFIG.opacity.toString())
							}
								let ANGLE = Math.atan2(event.y - CARD_CENTER[1], event.x - CARD_CENTER[0]) * 180 / Math.PI
								ANGLE = ANGLE < 0 ? ANGLE + 360 : ANGLE;
								CARD.style.setProperty('--start', (ANGLE + 90).toString())
				}
			}

		document.body.addEventListener('pointermove', UPDATE)

		return () => {
				document.body.removeEventListener('pointermove', UPDATE)
		}
	}, [])

	return (
		<RecentWorkBackground ref={containerRef}>
			<RecentWorkLayout ref={recentWorkRefProp}>
				{caseStudyArray.map((study: CaseStudy) => (
					<Link to={study.link} key={study.id} style={{ textDecoration: 'none' }}>
					<RecentWorkContainer backgroundImage={study.cardImageUrl} backgroundPosition={study.cardImagePosition || 'top left'}>
					<RecentWorkMask />
					</RecentWorkContainer>
					</Link>
				))}
			</RecentWorkLayout>
		</RecentWorkBackground>
	);
};

export default RecentWork;
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import caseStudiesImport from '../../content';
import { StyledCaseStudyContainer, ContentContainer } from './CaseStudyContainer.styled'; // Import ContentContainer here

type CaseStudyData = {
  // Include all the required properties for the case study data
  altText?: string;
  backgroundImageUrl?: string;
  description: string;
  id: string;
  imageUrl?: string;
  gradientColor?: 'orange' | 'blue';
  title: string;
  role: {
    description: string | string[];
    title: string;
  };
}

type CaseStudies = {
  [key: string]: CaseStudyData;
}

const caseStudies: CaseStudies = caseStudiesImport as CaseStudies;

const CaseStudyContainer = (props: any) => {
  const { id } = useParams<{ id: string }>();

  const caseStudyData: CaseStudyData | undefined = (id as keyof CaseStudies) in caseStudies ? caseStudies[id as keyof CaseStudies] : undefined;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!caseStudyData) {
    console.log('Case study not found');
    return <div>Case study not found</div>;
  }

  return (
    <StyledCaseStudyContainer 
      backgroundImage={caseStudyData.backgroundImageUrl}
      gradientColor={caseStudyData.gradientColor}  
    >
      <ContentContainer>
        {props.children}
      </ContentContainer>
    </StyledCaseStudyContainer>
  );
}

export default CaseStudyContainer;
